<script>
export default {
	name: 'SubTabMultiCity',
};
</script>

<script setup>
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import Tooltip from '~/components/Tooltip.vue';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import { useEventBus } from '@vueuse/core';
import { i18nGlobal } from '~/logic/i18n.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useIsCountryVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { pushAA_searchError } from '~/logic/adobe-analytic.js';
import yup from 'mh-yup';

dayjs.extend(isBetween);

const props = defineProps({
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	removeCTAText: { type: String, default: i18nGlobal.t('Remove') },
	addAnotherFlightCTAText: { type: String, default: i18nGlobal.t('Add another flight') },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },

	subHeaderTooltipCTATextMobileLabele: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	passengerSelectionQuestion: { type: String, default: '' },

	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },

	promoCode: { type: Object, default: null },
	countryVariants: { type: Array, default: () => [] },
});
const emit = defineEmits([
	'form-submission',
]);


const formWrapper = ref(null);
const passengerAndCabinClassEl = ref(null);
const ondPickerEls = ref([]);

const dateRangeValFrom = ref(null);
const dateRangeValTo = ref(null);

const { pageProperties, isEditorMode } = useGlobalAEMState();
const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const promoCodeTextField = ref(null);
const promoCodeValue = ref(null);

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const computedCabinClassValue = computed(() => {
	/*
		The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
		Economy: 1,
		Business: 2,
		Business Suite: 3.
		While form submission, due to different form has different cabin class value, even though their label(Economy, Business, Business Suite) are the same. 
		require to map to the actual cabin class value, which come from the component config.
	*/
	const index = parseInt(passengerAndCabinClassEl.value.cabinClassValue?.value) - 1;
	return props.cabinClassCashList[index].value;
});


const handleSubmitValid = async (values, actions) => {
	const valuesFiltered = Object.fromEntries(Object.entries(values).filter(([key, val]) => {
		return !key.startsWith('@');
	}));
	
	const valuesFinal = {};

	Object.entries(valuesFiltered).forEach(([key, val]) => {
		if (key.includes('departDate')) {
			valuesFinal[key] = dayjs(val).format('YYYYMMDDHHmm');
		} else {
			valuesFinal[key] = val;
		}
	});

	console.log('✅ handleSubmitValid values = ', valuesFinal);
	emit('form-submission', valuesFinal);

	const {
		passengerAdults,
		passengerTeens,
		passengerChildren,
		passengerInfants,
		cabinClass,
		promoCode,
		...rest
	} = valuesFinal;

	// gather all destinations locationFrom amal flag
	const amalAll = {};
	destinationList.value.forEach((item, index) => {
		const amalValue = item.locationTo._original.isAmalFlag;
		amalAll[`amal${index + 1}`] = amalValue;
	});

	try {
		// 1st api to get response with url and payload for 2nd api
		const apiResponse = await axios({
			method: 'post',
			url: props.flightSearchApiUrl,
			data: {
				'originCountry': destinationList.value[0].locationFrom._original.countryName,
				'flightClass': computedCabinClassValue.value,
				'adultCount': passengerAdults.toString(),
				...(isCountryVariant.value ? { 'teenCount': passengerTeens.toString() } : null),
				'childCount': passengerChildren.toString(),
				'infantCount': passengerInfants.toString(),
				'paymentType': 'cash',
				'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
				'promoCode': promoCode || '',
				'amcvId': window._satellite?.getVar('ECID') ?? '',
				'teaserCategory': getCookie('TeaserCategory') ?? '',
				...amalAll,
				...rest,
			},
		});
		
		const { url, payload } = apiResponse.data;
		
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key, value]] of entriesPayload.entries()) {
			console.log(`apiResponse => ${index}: ${key}: ${value}`);
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		form.submit();

	} catch (err) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', err);

		// from BE if status code === 400, it means promo code issue
		const { statusCode, message } = err.response.data;
		if (statusCode === 400) {
			// promo code error
			promoCodeTextField.value.setErrors(i18nGlobal.t(message));
			triggerAA_searchError({
				errorMsg: i18nGlobal.t(message),
			});
		}
	}
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const computedDestinationLabel = computed(() => {
	let list = props.ondSelection?.destinationLabel.split(',').map((str) => str.trim());
	const output = [];
	if (list.length < 6) {
		for (let i = 0, len = 7; i < len; i++) {
			if (list[i]) {
				output.push(list[i]);
			} else {
				output.push('');
			}
		}
		return output;
	}
	return list;
});

const destinationList = ref([
	{
		id: '@first',
		title: `${computedDestinationLabel.value[0]}`,
		locationFrom: ref(null),
		locationTo: ref(null),
		departureDate: ref(null),
	},
	{
		id: '@second',
		title: `${computedDestinationLabel.value[1]}`,
		locationFrom: ref(null),
		locationTo: ref(null),
		departureDate: ref(null),
	},
]);

const isCountryVariant = ref(false);

const addDestination = async () => {
	destinationList.value.push({
		id: `@${Math.round(Math.random() * 10000000000000000)}`,
		title: `${computedDestinationLabel.value[destinationList.value.length]}`,
		locationFrom: ref(null),
		locationTo: ref(null),
		departureDate: ref(null),
	});
	// await nextTick();
	// emitMultiCitiesChanged();
};
const removeDestinationById = async (id) => {
	destinationList.value.splice(destinationList.value.findIndex((d) => d.id === id), 1);
	// await nextTick();
	// emitMultiCitiesChanged();
};

const loadDestinationListFromQuery = async (data) => {
	for (const [index, item] of data.entries()) {
		destinationList.value.splice(index, 1, {
			id: `@${Math.round(Math.random() * 10000000000000000)}`,
			title: `${computedDestinationLabel.value[destinationList.value.length]}`,
			locationFrom: item.locationFrom,
			locationTo: item.locationTo,
			departureDate: item.dateDeparture,
		});
	}
	nextTick().then(async () => {
		for (const item of ondPickerEls.value) {
			await item.updateFromValue();
			item.updateToValue();
		}
	});
};


const bookingWidgetBus = useEventBus('booking-widget');

/* const emitMultiCitiesChanged = () => {
	bookingWidgetBus.emit('refresh-scroll-trigger');
}; */

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	const list = destinationList.value;

	if (!form) return true;
	const isError = list.some((item) => {
		if (!item.departureDate ||
			!item.locationFrom ||
			!item.locationTo
		) return true;
		return false;
	});
	return isError;
});

const dynamicMinDate = (item, index) => {
	if (index === 0) return '';
	if (index > 0 && destinationList.value[index - 1].departureDate) {
		return dayjs(destinationList.value[index - 1].departureDate).format();
	}
};

const customValidationDatepickerFrom = (index, value) => {
	const hasPreviousDate = destinationList.value[index - 1]?.departureDate;
	const isBeforePreviousDate = hasPreviousDate && dayjs(value).isBefore(dayjs(hasPreviousDate));
	// const hasNextDate = destinationList.value[index + 1]?.departureDate;	
	// const isFirstDateMoreThanSecondDate = dayjs(value).isAfter(dayjs(hasNextDate));
	// const isInBetween = dayjs(value).isBetween(dayjs(hasPreviousDate), dayjs(hasNextDate));
	if (isBeforePreviousDate) return i18nGlobal.t('Date selected should be the same or after the date of the previous configured row');
	return true;
};

useSyncPassengerDetails(passengerAndCabinClassEl);

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'multi-city',
});

registerQueryChange(async (query) => {
	if (!query) return;
	let dataObject = [];

	for (let i = 0; i < 6; i++) {
		let data = Object.keys(query).filter((key) => key.includes(i + 1)).reduce((cur, key) => {
			var keyNameToReturn = key.replace(/\d+/g, '');
			return Object.assign(cur, { [keyNameToReturn]: query[key] });
		}, {});
		dataObject.push(data);
	}

	dataObject = dataObject.filter((value) => Object.keys(value).length !== 0);

	await loadDestinationListFromQuery(dataObject);

	promoCodeValue.value = query.promoCode ?? null;
	
	await nextTick();

	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.teensCount ? { teens: parseInt(query.teensCount) } : null),
		...(query.childrenCount ? { children: parseInt(query.childrenCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
		...(query.cabinClass ? { cabinClass: parseInt(query.cabinClass) } : null),
	});
	
});

watch(destinationList, (newData) => {
	const list = new Set();

	newData.forEach((obj) => {
		const { locationFrom, locationTo } = obj;
		const isVariant = useIsCountryVariant(props.countryVariants, { origin: locationFrom, destination: locationTo });
		list.add(isVariant);
	});

	if ([...list].includes(true)) {
		isCountryVariant.value = true;
	} else {
		isCountryVariant.value = false;

		// reset teen passenger count, once user select non-uk airport
		passengerAndCabinClassEl.value.setValues({
			teens: 0,
		});
	}

}, { deep: true });

const dateRangePickerEls = ref([]);

const focusToDatePicker = (newValue, elIndex) => {
	if (newValue) dateRangePickerEls.value[elIndex].inputElFrom?.focus();
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withMalaysiaAirlinesLabel,
		'CODESHARE': props.ondSelection?.withCodeShareAndPartnerNetworkLabel,
	};
});


const siteName = window.siteName;

const promoFieldValidationFunc = (value) => {
	let yupSchema = yup.string().nullable().max(8, props.promoCode?.promoCodeErrorMessage);
	
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.promoCodeLabel,
		type: 'other',
	};
	pushAA_searchError([errorInfo, webInteractions]);
};

const formWrapperPromoCodeError = computed(() => {
	if (formWrapper.value !== null) {
		const form = formWrapper.value;

		return form.errors['promoCode'];
	}

	return null;
});

watch(formWrapperPromoCodeError, (newValueErrorText) => {
	if (newValueErrorText) {
		triggerAA_searchError({
			errorMsg: newValueErrorText,
		});
	}
});

</script>

<template>
<div class="SubTabMultiCity">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		:data-use-theme="siteName"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>

		<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

		<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.subHeaderTabTooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.subHeaderTabTooltipLabel }}
			</template>
			<template #tooltip-content>
				{{ props.subHeaderTabTooltipBody }}
			</template>
			<template #mobile-cta-label>
				{{ props.subHeaderTooltipCTATextMobileLabel }}
			</template>
		</Tooltip>
		
		<div class="">
			<section
				v-for="(item, index) in destinationList"
				:key="item.id"
				class="pb-6 mb-4 border-b-2 border-neutral-grey-light last:border-b-0 last:mb-0"
			>
				<div class="flex items-center mb-2">
					<div class="typo-category-title">
						<p class="uppercase">{{ item.title }}</p>
					</div>
			
					<div class="ml-auto">
						<button
							v-if="index >= 2"
							v-aa="[
								{
									clickName: props.removeCTAText,
									clickComponentType: 'Button',
									componentName: 'SubTabMultiCity',
									componentID: props.componentIdAA,
								},
								{
									name: props.removeCTAText,
									type: 'other',
								},
							]"
							v-button-press
							type="button"
							class="btn-generic-b text-primary-blue-base font-semibold px-4 gap-2"
							@click="removeDestinationById(item.id)"
						>
							<icon-fas-trash-can class="fill-current" />
							{{ props.removeCTAText }}
						</button>
					</div>
				</div>
			
				<div class="flex gap-6 xl:gap-3 lg:flex-col field-wrapper">
					<div class="w-6/10 lg:w-full component-wrapper">
						<ONDPicker
							ref="ondPickerEls"
							v-model:modelValueFrom="item.locationFrom"
							v-model:modelValueTo="item.locationTo"
							:nameFrom="`${item.id}-location-from`"
							:nameTo="`${item.id}-location-to`"
							ondListIdentifier="FLIGHT_SEARCH_MULTI_CITY"
							:requiredFrom="true"
							:requiredTo="true"
							:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
							:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
							:labelTextFrom="props.ondSelection?.fromLabel"
							:labelTextTo="props.ondSelection?.toLabel"
							:noResultsText="props.ondSelection?.noResultMessage"
							:groupLabelsOverwrite="groupLabelsOverwrite"
							:enableONDLocaleTranslation="props.enableONDLocaleTranslation"
							@update:modelValueTo="focusToDatePicker($event, index);"
						>
							<template #from-picker-mobile-title>
								<span>{{ props.fromQuestionMobile }}</span>
							</template>
							<template #to-picker-mobile-title>
								<span>{{ props.toQuestionMobile }}</span>
							</template>
						</ONDPicker>
					</div>
					<div class="w-4/10 lg:w-full component-wrapper">
						<DateRangePicker
							ref="dateRangePickerEls"
							v-model:modelValueFrom="item.departureDate"
							:textFieldAttrsFrom="{
								ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
								placeholder: $t('Select a date'),
							}"
							:nameFrom="`${item.id}-departure-date`"
							:nameTo="`${item.id}-return-date`"
							maxDate="t+361d"
							:minDate="dynamicMinDate(item, index)"
							:disablePastDates="true"
							:requiredFrom="true"
							:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
							:forceOneWay="true"
							:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
							:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
							:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
							:customValidationDatepickerFrom="customValidationDatepickerFrom.bind(null, index)"
						>
							<template #mobile-title>
								{{ props.datePickerQuestionMobile }}
							</template>
						</DateRangePicker>
					</div>
				</div>
			</section>
		</div>
		
		<div class="hidden flex flex-wrap" aria-hidden="true">
			<div
				v-for="(item, index) in destinationList"
				:key="item.id"
				class="w-1/3 p-2 border"
			>
				<TextField
					:key="`${item.id}-destination-${[index + 1]}-location-from`"
					:name="`originAirportCode${[index + 1]}`"
					:modelValue="item.locationFrom?.value"
				/>
				<TextField
					:key="`${item.id}-destination-${[index + 1]}-location-to`"
					:name="`destAirportCode${[index + 1]}`"
					:modelValue="item.locationTo?.value"
				/>
				<TextField
					:key="`${item.id}-destination-${[index + 1]}-departure-date`"
					:name="`departDate${[index + 1]}`"
					:modelValue="item.departureDate"
				/>
			</div>
		</div>
		
		<div class="flex justify-start">
			<CTAButton
				v-aa="[
					{
						clickName: props.addAnotherFlightCTAText,
						clickComponentType: 'Button',
						componentName: 'SubTabMultiCity',
						componentID: props.componentIdAA,
					},
					{
						name: props.addAnotherFlightCTAText,
						type: 'other',
					},
				]"
				class="btn-add-flight"
				sizeType="small"
				styleType="secondary"
				iconStyleLeft="solid"
				iconIdLeft="circle-plus"
				:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
				:disabled="destinationList.length >= 6"
				@click="addDestination"
			>
				<span class="font-semibold">{{ props.addAnotherFlightCTAText }}</span>
			</CTAButton>
		</div>
		
		<div class="flex mt-6 gap-6 md:flex-col">
			<div class="flex flex-grow gap-6 md:flex-col">
				<div class="w-6/10 lg:w-full component-wrapper">
					<PassengerAndCabinClass
						ref="passengerAndCabinClassEl"
						:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
						:passengerLabel="props.passengerSelection?.passengerLabel"
						:adultLabel="props.passengerSelection?.adultLabel"
						:teenagerLabel="props.passengerSelection?.teenagerLabel"
						:childrenLabel="props.passengerSelection?.childrenLabel"
						:infantLabel="props.passengerSelection?.infantLabel"
						:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
						:economyLabel="props.passengerSelection?.economyLabel"
						:businessLabel="props.passengerSelection?.businessLabel"
						:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
						:isShowEconomyClass="!props.passengerSelection?.isHideEconomy"
						:isShowBusinessClass="!props.passengerSelection?.isHideBusiness"
						:isShowBusinessSuiteClass="!props.passengerSelection?.isHideBusinessSuite"
						:isCountryVariant="isCountryVariant"
					>
						<template #after-passengers>
							<AppHyperlink
								class="font-semibold"
								:href="props.passengerSelection?.groupBookingPageURL"
								:showExternalLinkIcon="true"
								target="_blank"
							>
								{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
							</AppHyperlink>
						</template>
						<template #mobile-title>
							{{ props.passengerSelectionQuestion }}
						</template>
					</PassengerAndCabinClass>
				</div>
				<div v-if="!props.promoCode?.hidePromoCodeField" class="w-4/10 lg:w-full">
					<TextField
						ref="promoCodeTextField"
						v-model="promoCodeValue"
						name="promoCode"
						variant="booking-widget"
						:ariaLabel="props.promoCode?.promoCodeLabel"
						placeholder="E.g. 8990"
						:allowedKeys="/[a-zA-Z0-9]/"
						:validation="promoFieldValidationFunc"
					></TextField>
				</div>
			</div>
			<div class="ml-auto md:ml-0 cta-wrapper">
				<CTAButton
					v-aa="[
						{
							clickName: props.searchFlightCTAText,
							clickComponentType: 'Button',
							componentName: 'SubTabMultiCity',
							componentID: props.componentIdAA,
						},
						{
							name: props.searchFlightCTAText,
							type: 'exit',
						},
					]"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:isSubmit="true"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
				>
					{{ props.searchFlightCTAText }}
				</CTAButton>
			</div>
		</div>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p>{{ props.note }}</p>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabMultiCity {
	:deep(.btn-add-flight) {
		height: 48px;
		
		.icon {
			width: 24px;
			height: 24px;
			margin-top: -5px;
		}
	}

	[data-use-theme="enrich-portal"] {
		.field-wrapper {
			flex-direction: column;
		}

		.component-wrapper {
			width: 100% !important;
		}

		.cta-wrapper{
			@apply m-auto;
		}
	}
}
</style>
