<script>
export default {
	name: 'MhUpgradePopUp',
	inheritAttrs: false,
};
</script>

<script setup>
import { Teleport } from 'vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import useDisableScroll from '~composables/useDisableScroll.js';
import { sleep } from '~/logic/helpers/utils.js';

const props = defineProps({
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	image: { type: String, default: '' },
	imageAltText: { type: String, default: '' },
	promotionBody: { type: String, default: '' },
	ctaYesButtonLabel: { type: String, default: '' },
	ctaNoButtonLabel: { type: String, default: '' },
	ctaYesButtonPath: { type: String, default: '#' },
	ctaNoButtonPath: { type: String, default: '#' },
});

const { isEditorMode } = useGlobalAEMState();
const hasUserDismissed = ref(false);
const rootEl = ref(null);

const dismissOverlayByUser = () => {
	window.location.href = props.ctaNoButtonPath;
	hasUserDismissed.value = true;
};

const isPopUpVisibleComputed = computed(() => {
	if (isEditorMode.value) return true;
	if (hasUserDismissed.value) return false;
	const authorConfiguredHidden = isMobileViewport.value ? (props.isHideInMobile) : (props.isHideInDesktop);
	return authorConfiguredHidden === false;
});

if (!isEditorMode.value) {
	// due to multiple 'useDisableScroll' is in the page, it messes up with the scrollbar
	onMounted(async () => {
		await sleep(500); // <-- this is a workaround / hack to force the below 'useDisableScroll' statement to take effect later
		useDisableScroll(isPopUpVisibleComputed);
	});
}


</script>

<template>
<component
	:is="isEditorMode ? 'div' : Teleport"
	to="body"
>
	<Transition
		name="overlay-fade-and-zoom"
		appear
	>
		<div v-if="isPopUpVisibleComputed" class="MHUpgradePopUp backdrop">
			<div class="overlay-container">
				<div class="overlay-header">
					<button
						type="button"
						class="absolute top-4 right-4"
						aria-label="Popup close button"
						@click="dismissOverlayByUser"
					>
						<icon-far-xmark class="w-6 h-6 white" />
					</button>
					<div class="overlay-header-image w-full">
						<img
							class="w-full h-full object-center object-cover"
							:src="props.image"
							:alt="props.imageAltText"
						/>
					</div>
				</div>
				<div class="overlay-body px-6 mt-4">
					<div v-html-sanitize="props.promotionBody" class="author-html-container line-clamp-5"></div>
				</div>
				<div class="overlay-footer px-6 mt-6 mb-10">
					<AppHyperlink
						:href="props.ctaYesButtonPath"
						class="flex-grow basis-0 hover:no-underline"
					>
						<CTAButton
							:ctaText="props.ctaYesButtonLabel"
							sizeType="small"
							ctaSizing="fluid"
							styleType="primary"
							containerClass="w-full"
							tabindex="-1"
						/>
					</AppHyperlink>
					<AppHyperlink
						:href="props.ctaNoButtonPath"
						class="flex-grow basis-0 hover:no-underline"
					>
						<CTAButton
							:ctaText="props.ctaNoButtonLabel"
							sizeType="small"
							ctaSizing="fluid"
							styleType="secondary"
							containerClass="flex-grow basis-0"
							@click="dismissOverlayByUser"
						/>
					</AppHyperlink>
				</div>
			</div>
		</div>
	</Transition>
</component>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MHUpgradePopUp {
	&.backdrop {
		position: fixed;
		z-index: 10000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: color.change(black, $alpha: 0.8);
		display: flex;
		justify-content: flex-end;
		transition: opacity 0.45s ease;
	}

	.overlay-container {
		background-color: var(--overlay-container-bg-color, white);
		max-width: 408px;
		width: 100%;
		margin: auto;
		overflow: hidden;
		@apply rounded-3xl;
	}

	.overlay-header {
		position: relative;
	}
	.overlay-header-image {
		aspect-ratio: 3/2;
	}

	.overlay-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		@apply gap-5;
		
		@media #{var.$query-max-md} {
			flex-direction: column;
			@apply gap-4;
		}
	}
}

</style>