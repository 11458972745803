import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

import {
	Constants,
	getMappedComponent,
	AllowedComponentsPlaceholderList,
	Utils,
	modelProvider,
	editableProvider,
} from '@custom/aem-vue-editable-components';

import { refreshAEMEditableOverlays } from '~/logic/aem/refresh-aem-editable-overlays.js';
import ContainerBatikPattern from '~/components/ContainerBatikPattern.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import TextField from '~/components/form/TextField.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import RecaptchaGoogle from '~/components/form/RecaptchaGoogle.vue';
import RecaptchaMT from '~/components/form/RecaptchaMT.vue';
import AppSpinner from '~/components/AppSpinner.vue';
import axios from 'axios';
import yup from 'mh-yup';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { getCookie } from '~/logic/helpers/cookies';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const MHLayoutContainer = defineComponent({
	name: 'MHLayoutContainer',
	editConfig: {
		resourceType: 'mh/components/mh-layout-container',
		isEmpty (props) {
			return props.cqItemsOrder && (props.cqItemsOrder.length > 0);
		},
	},
	components: {
		AllowedComponentsPlaceholderList,
		ContainerBatikPattern,
	},
	inheritAttrs: false,
	props: {
		columnClassNames: { type: Object, default: () => ({}) },
		gridClassNames: { type: String, default: 'aem-Grid aem-Grid--12 aem-Grid--default--12' },

		// eslint-disable-next-line
		_AllowedComponentsPlaceholderListEmptyLabel: { type: String, default: 'No allowed components' },
		allowedComponents: { type: Object, default: () => ({}) },
		title: { type: String, default: '' },

		cqForceReload: { type: Boolean, default: false },
		cqPath: { type: String, default: '' },
		cqItems: { type: Object, default: () => ({}) },
		cqItemsOrder: { type: Array, default: () => [] },

		columnCount: { type: Number, default: 12 },
		cqType: { type: String, default: '' },
		appliedCssClassNames: { type: String, default: '' },

		containerClass: { type: String, default: '' },

		pagePath: { type: String, default: '' },
		itemPath: { type: String, default: '' },
		hasEditableCapability: { type: Boolean, default: true },
		
		layoutType: { type: String, default: '' },
		
		// bg color applicable for all layoutType
		backgroundColor: { type: String, default: undefined },
		
		// only applicable to 'bottom-rounded-corner-with-shadow' layoutType
		bottomRoundedSpaceColor: { type: String, default: undefined },
		
		// space color for any 'batik' layoutType
		topSpaceColor: { type: String, default: undefined },
		bottomSpaceColor: { type: String, default: undefined },
		batikTopSpaceColor: { type: String, default: undefined },
		batikBottomSpaceColor: { type: String, default: undefined },
		magTopSpaceColor: { type: String, default: undefined },
		magBottomSpaceColor: { type: String, default: undefined },

		// space color for any 'rounded-corner-rectangle' layoutType
		roundedCornerTopSpaceColor: { type: String, default: undefined },
		roundedCornerBottomSpaceColor: { type: String, default: undefined },
		
		isHideInDesktop: { type: Boolean, default: false },
		isHideInMobile: { type: Boolean, default: false },
		
		// This prop is newly declared (previously it was part of "attrs"). Does this break anything?
		items: { type: Array, default: () => [] },
		
		// padding props to add spacing right and left for desktop and mobile
		desktopPaddingLeft: { type: Number, default: undefined },
		desktopPaddingRight: { type: Number, default: undefined },
		mobilePaddingLeft: { type: Number, default: undefined },
		mobilePaddingRight: { type: Number, default: undefined },

		topSectionCurve: { type: String, default: undefined },
		bottomSectionCurve: { type: String, default: undefined },
		topSectionCurveBatik: { type: String, default: undefined },
		bottomSectionCurveBatik: { type: String, default: undefined },
		
		// MHH props
		mhhTopSectionCurve: { type: String, default: undefined },
		mhhBottomSectionCurve: { type: String, default: undefined },
		mhhTopSpaceColor: { type: String, default: undefined },
		mhhBottomSpaceColor: { type: String, default: undefined },
		
		// AMAL props
		amalTopSectionCurve: { type: String, default: undefined },
		amalBottomSectionCurve: { type: String, default: undefined },
		amalTopSpaceColor: { type: String, default: undefined },
		amalBottomSpaceColor: { type: String, default: undefined },

		// Firefly props
		fireflyTopSpaceColor: { type: String, default: undefined },
		fireflyBottomSpaceColor: { type: String, default: undefined },

		// MH - MH Sports JDT portal - Lock Screen props
		userGroup: { type: String, default: '' },
		isEnable: { type: Boolean, default: false },
		recaptchaType: { type: String, default: 'MT Recaptcha' },
		loginBtnText: { type: String, default: 'Login' },
	},
	setup (props, context) {
		const instance = getCurrentInstance();
		const instanceEditConfig = instance.type.editConfig;

		const siteName = window.siteName;

		const { isEditorMode } = useGlobalAEMState();

		const {
			aemProperties,
			componentId,
			computedCqPath,
		} = modelProvider({ props, context });

		const {
			wrapperHtmlAttributes,
			wrapperHtmlClass,
			emptyPlaceholderDiv,
		} = editableProvider({ aemProperties, computedCqPath, props, context, editConfig: instanceEditConfig });

		const isLoggedIn = ref(false);
		const isLoading = ref(false);
		const userName = ref('');
		const password = ref('');
		const loginErrorMsg = ref('');
		const recaptchaGoogleEl = ref(null);
		const recaptchaMTEl = ref(null);

		const getItemComponent = (ItemComponent, itemProps, itemKey) => {
			const { cqPath, columnClassNames } = aemProperties.value;

			const itemPath = cqPath ? (`${cqPath}/${itemKey}`) : itemKey;
			const containerClass = `${itemProps?.className ?? ''} ${columnClassNames?.[itemKey] ?? ''}`;

			return (
				<ItemComponent
					{...itemProps}
					cqPath={itemPath}
					containerClass={containerClass}
					key={itemKey}
				/>
			);
		};
		const childComponents = computed(() => {
			const { cqItems, cqItemsOrder } = aemProperties.value;
			if (!cqItems || !cqItemsOrder) return [];

			return cqItemsOrder.map((itemKey) => {
				const itemProps = Utils.modelToProps(cqItems[itemKey]);
				if (!itemProps) return null;

				const ItemComponent = getMappedComponent(itemProps.cqType);
				if (!ItemComponent) return null;
				return getItemComponent(ItemComponent, itemProps, itemKey);
			});
		});


		const placeholderProps = computed(() => {
			const { cqPath } = aemProperties.value;
			return {
				'class': `${Constants.NEW_SECTION_CLASS_NAMES} ${Constants._RESPONSIVE_GRID_PLACEHOLDER_CLASS_NAMES}`,
				'data-cq-data-path': `${computedCqPath.value}/*`,
			};
		});
		const placeholderComponent = computed(() => {
			if (!AuthoringUtils.isInEditor()) return null;

			return (
				<div
					{...placeholderProps.value}
				></div>
			);
		});
		
		const hideInDesktopOrMobileClassname = computed(() => {
			if (AuthoringUtils.isInEditor()) return '';
			return `${props.isHideInDesktop ? '>md:hidden' : ''} ${props.isHideInMobile ? 'md:hidden' : ''}`;
		});

		const mergedWrapperClass = computed(() => {
			return `${wrapperHtmlClass.value} ${aemProperties.value.appliedCssClassNames}`;
		});

		const siteKey = props.recaptchaType === 'google-recaptcha' ? '6LdhpyklAAAAAOkdkdtcREPPMjxdRuZJcldEK_y9' : 'MTPublic-77FVOz99s';

		async function getFormRecaptchaToken () {
			let token = null;
			if (recaptchaMTEl.value?.isReady) {
				token = recaptchaMTEl.value.getRecaptchaToken();
			}
			return token;
		}

		const submitForm = async () => {
			loginErrorMsg.value = '';
			const token = await getFormRecaptchaToken();
			if (!token) {
				loginErrorMsg.value = 'Please fill the recaptha';
				return;
			}
			isLoading.value = true;
			const formData = new FormData();
			formData.append('userName', userName.value);
			formData.append('password', password.value);
			formData.append('userGroup', props.userGroup);
			axios.post('/bin/mh/revamp/mhsports/jdtportalauthentication', formData)
				.then((response) => {
					isLoading.value = false;
					if (response.data.code === 'JDTPS') {
						isLoggedIn.value = true;
					}
				}).catch((err) => {
					loginErrorMsg.value = err.response.data.message;
					isLoading.value = false;
				});
		};

		onMounted(() => {
			setTimeout(() => {
				if (AuthoringUtils.isInEditor()) refreshAEMEditableOverlays();
			}, 2000);

			if (getCookie('jdt_loggedinuser')) {
				isLoggedIn.value = true;
			}
		});
		
		const innerContainerWrapperAttrs = computed(() => {
			return {
				class: `inner-container ${hideInDesktopOrMobileClassname.value} ${Constants?._CONTAINER_CLASS_NAMES || ''} ${aemProperties.value?.gridClassNames || ''}`,
				...(AuthoringUtils.isInEditor() ? { 'data-cq-data-path': computedCqPath.value } : null),
			};
		});
		
		const paddingContainerStyles = computed(() => {
			
			const desktopPaddingLeft = `${props.desktopPaddingLeft !== undefined || props.desktopPaddingLeft !== 0 ? `padding-left: ${props.desktopPaddingLeft}px;` : null }`;
			const desktopPaddingRight = `${props.desktopPaddingRight !== undefined || props.desktopPaddingRight !== 0 ? `padding-right: ${props.desktopPaddingRight}px;` : null }`;
			
			const mobilePaddingLeft = `${props.mobilePaddingLeft !== undefined || props.mobilePaddingLeft !== 0 ? `padding-left: ${props.mobilePaddingLeft}px;` : null }`;
			const mobilePaddingRight = `${props.mobilePaddingRight !== undefined || props.mobilePaddingRight !== 0 ? `padding-right: ${props.mobilePaddingRight}px;` : null }`;
			
			if (!isMobileViewport.value) {
				return `${desktopPaddingLeft} ${desktopPaddingRight}`;
			} else {
				return `${mobilePaddingLeft} ${mobilePaddingRight}`;
			}
		});
		
		const getMainContent = () => {
			/* const wrapperAttrs = {
				class: `${Constants._CONTAINER_CLASS_NAMES} ${aemProperties.value.gridClassNames}`,
				...(AuthoringUtils.isInEditor() ? { 'data-cq-data-path': computedCqPath.value } : null),
			}; */
			
			if (!props.layoutType || props.layoutType === 'none') {
				return (
					<div style={`background-color: ${props.backgroundColor}; ${paddingContainerStyles.value}`}>
						{childComponents.value}
						{placeholderComponent.value}
					</div>
				);
			}

			if (props.layoutType === 'batik-1') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.topSpaceColor}
						btmEmptySpaceBgColor={props.bottomSpaceColor}
						useBatikPattern={false}
						useCornerBand={true}
						containerBgColor={props.backgroundColor || '#1F4074'}
						topCurveStyle={'movingForward'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'batik-2') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.batikTopSpaceColor}
						btmEmptySpaceBgColor={props.batikBottomSpaceColor}
						useBrighterBatik={true}
						containerBgColor={props.backgroundColor || '#1F4074'}
						contentBgColor={props.backgroundColor || '#1F4074'}
						topCurveStyle={props.topSectionCurveBatik || 'movingForward'}
						bottomCurveStyle={props.bottomSectionCurveBatik || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'batik-3') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.batikTopSpaceColor}
						btmEmptySpaceBgColor={props.batikBottomSpaceColor}
						containerBgColor={props.backgroundColor || '#00818d'}
						topCurveStyle={props.topSectionCurveBatik || 'movingForward'}
						bottomCurveStyle={props.bottomSectionCurveBatik || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'batik-4') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.batikTopSpaceColor}
						btmEmptySpaceBgColor={props.batikBottomSpaceColor}
						containerBgColor={props.backgroundColor || '#1F4074'}
						contentBgColor={props.backgroundColor || '#1F4074'}
						topCurveStyle={props.topSectionCurveBatik || 'movingForward'}
						bottomCurveStyle={props.bottomSectionCurveBatik || 'bothRoundedDown'}
						useFloralPattern={true}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'batik-5') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.batikTopSpaceColor}
						btmEmptySpaceBgColor={props.batikBottomSpaceColor}
						containerBgColor={props.backgroundColor || '#00818d'}
						contentBgColor={props.backgroundColor || '#00818d'}
						topCurveStyle={props.topSectionCurveBatik || 'movingForward'}
						bottomCurveStyle={props.bottomSectionCurveBatik || 'bothRoundedDown'}
						useFloralPattern={true}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'mag-songket-blue') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.magTopSpaceColor}
						btmEmptySpaceBgColor={props.magBottomSpaceColor}
						useBatikBlueMag={true}
						containerBgColor={props.backgroundColor || '#00425F'}
						contentBgColor={props.backgroundColor || '#00425F'}
						topCurveStyle={props.topSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.bottomSectionCurve || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'mag-songket-blue-background') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.magTopSpaceColor}
						btmEmptySpaceBgColor={props.magBottomSpaceColor}
						useBrighterBatikBlueMag={true}
						containerBgColor={props.backgroundColor || '#00425F'}
						topCurveStyle={props.topSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.bottomSectionCurve || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'mag-songket-teal') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.magTopSpaceColor}
						btmEmptySpaceBgColor={props.magBottomSpaceColor}
						useBatikTealMag={true}
						containerBgColor={props.backgroundColor || '#00818D'}
						contentBgColor={props.backgroundColor || '#00818D'}
						topCurveStyle={props.topSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.bottomSectionCurve || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}

			if (props.layoutType === 'mag-songket-teal-background') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.magTopSpaceColor}
						btmEmptySpaceBgColor={props.magBottomSpaceColor}
						useBrighterBatikTealMag={true}
						containerBgColor={props.backgroundColor || '#00818D'}
						topCurveStyle={props.topSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.bottomSectionCurve || 'bothRoundedDown'}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// MHH grey hibiscus pattern
			if (props.layoutType === 'mhh-grey-hibiscus') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.mhhTopSpaceColor}
						btmEmptySpaceBgColor={props.mhhBottomSpaceColor}
						useGreyHibiscusPattern={true}
						containerBgColor="#F0F3F5"
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.mhhTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.mhhBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// MHH orange hibiscus pattern
			if (props.layoutType === 'mhh-orange-hibiscus') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.mhhTopSpaceColor}
						btmEmptySpaceBgColor={props.mhhBottomSpaceColor}
						useOrangeHibiscusPattern={true}
						containerBgColor="#FFF9F0"
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.mhhTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.mhhBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// AMAL blue islamic pattern
			if (props.layoutType === 'amal-blue') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.amalTopSpaceColor}
						btmEmptySpaceBgColor={props.amalBottomSpaceColor}
						useBlueIslamicPattern={true}
						containerBgColor="#0F5CA2"
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.amalTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.amalBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// AMAL teal islamic pattern
			if (props.layoutType === 'amal-teal-ultralight') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.amalTopSpaceColor}
						btmEmptySpaceBgColor={props.amalBottomSpaceColor}
						useTealIslamicPattern={true}
						containerBgColor="#EAF8F8"
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.amalTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.amalBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// AMAL white islamic pattern
			if (props.layoutType === 'amal-white') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.amalTopSpaceColor}
						btmEmptySpaceBgColor={props.amalBottomSpaceColor}
						useAmalWhiteIslamicPattern={true}
						containerBgColor="#FFFFFF"
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.amalTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.amalBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// AMAL light blue islamic pattern
			if (props.layoutType === 'amal-light-blue') {
				return (
					<ContainerBatikPattern
						topEmptySpaceBgColor={props.amalTopSpaceColor}
						btmEmptySpaceBgColor={props.amalBottomSpaceColor}
						useAmalLightBlueIslamicPattern={true}
						contentBgColor={props.backgroundColor || 'transparent'}
						topCurveStyle={props.amalTopSectionCurve || 'bothRoundedUp'}
						bottomCurveStyle={props.amalBottomSectionCurve || 'bothRoundedDown'}
						useBatikPattern={false}
					>
						{childComponents.value}
						{placeholderComponent.value}
					</ContainerBatikPattern>
				);
			}
			
			// AMAL glassy-background with Gold outline
			if (props.layoutType === 'semi-transparent-with-gold-outline') {
				return (
					<div class="relative">
						<div
							class="rounded-[20px] overflow-hidden relative z-10 border-1"
							style={`background-color: ${props.backgroundColor}; ${paddingContainerStyles.value}; border-color: #CEAF87`}
						>
							<div
								style="background: linear-gradient(153deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
										padding: 1px;
										backdrop-filter: blur(42px);"
							>
								{childComponents.value}
								{placeholderComponent.value}
							</div>
						</div>
						
						<div class="backdrop-container md:hidden absolute w-full h-full top-0 left-0">
							<div class="backdrop backdrop-top h-[50%]" style={`background-color: ${props.topSpaceColor}`}></div>
							<div class="backdrop backdrop-btm h-[50%]" style={`background-color: ${props.bottomSpaceColor}`}></div>
						</div>
					</div>
				);
			}
			
			
			if (props.layoutType === 'rounded-corner-rectangle') {
				return (
					<div class="relative">
						<div
							class="rounded-3xl overflow-hidden relative z-10"
							style={`background-color: ${props.backgroundColor}; ${paddingContainerStyles.value}`}
						>
							{childComponents.value}
							{placeholderComponent.value}
						</div>
						
						<div class="backdrop-container md:hidden absolute w-full h-full top-0 left-0">
							<div class="backdrop backdrop-top h-[50%]" style={`background-color: ${props.roundedCornerTopSpaceColor}`}></div>
							<div class="backdrop backdrop-btm h-[50%]" style={`background-color: ${props.roundedCornerBottomSpaceColor}`}></div>
						</div>
					</div>
				);
			}

			if (props.layoutType === 'bottom-rounded-corner-with-shadow') {
				return (
					<div style={`background-color: ${props.bottomRoundedSpaceColor || 'transparent'};`}>
						<div
							class="rounded-b-9xl md:rounded-b-5xl shadow-type-a"
							style={`background-color: ${props.backgroundColor};  ${paddingContainerStyles.value}`}
						>
							{childComponents.value}
							{placeholderComponent.value}
						</div>
					</div>
				);
			}

			if (props.layoutType === 'grey-rounded-border') {
				return (
					<div class="relative">
						<div
							class="rounded-3xl overflow-hidden relative z-10 border-1"
							style={`background-color: ${props.backgroundColor}; ${paddingContainerStyles.value}`}
						>
							{childComponents.value}
							{placeholderComponent.value}
						</div>
						
						<div class="backdrop-container md:hidden absolute w-full h-full top-0 left-0">
							<div class="backdrop backdrop-top h-[50%]" style={`background-color: ${props.topSpaceColor}`}></div>
							<div class="backdrop backdrop-btm h-[50%]" style={`background-color: ${props.bottomSpaceColor}`}></div>
						</div>
					</div>
				);
			}

			if (props.layoutType === 'firefly-style1') {
				return (
					<div class="relative">
						<div
							class="rounded-10xl md:rounded-5xl rounded-tl-15xl md:rounded-tl-[60px] !rounded-br-none overflow-hidden relative z-10"
							style={`background-color: ${props.backgroundColor};`}
						>
							{childComponents.value}
							{placeholderComponent.value}
						</div>
						
						<div class="backdrop-container md:hidden absolute w-full h-full top-0 left-0">
							<div class="backdrop backdrop-top h-[50%]" style={`background-color: ${props.fireflyTopSpaceColor}`}></div>
							<div class="backdrop backdrop-btm h-[50%]" style={`background-color: ${props.fireflyBottomSpaceColor}`}></div>
						</div>
					</div>
				);
			}

			if (props.layoutType === 'firefly-style2') {
				return (
					<div class="relative">
						<div
							class="rounded-tr-15xl rounded-br-15xl md:rounded-tr-[60px] md:rounded-br-[60px] overflow-hidden relative z-10"
							style={`background-color: ${props.backgroundColor};`}
						>
							{childComponents.value}
							{placeholderComponent.value}
						</div>
						
						<div class="backdrop-container md:hidden absolute w-full h-full top-0 left-0">
							<div class="backdrop backdrop-top h-[50%]" style={`background-color: ${props.fireflyTopSpaceColor}`}></div>
							<div class="backdrop backdrop-btm h-[50%]" style={`background-color: ${props.fireflyBottomSpaceColor}`}></div>
						</div>
					</div>
				);
			}
		};

		return {
			aemProperties,
			componentId,
			computedCqPath,

			wrapperHtmlAttributes,
			wrapperHtmlClass,
			mergedWrapperClass,
			emptyPlaceholderDiv,

			childComponents,

			placeholderProps,
			placeholderComponent,
			
			getMainContent,
			props,
			innerContainerWrapperAttrs,
			hideInDesktopOrMobileClassname,
			isLoggedIn,
			isLoading,
			userName,
			password,
			loginErrorMsg,
			siteKey,
			recaptchaGoogleEl,
			recaptchaMTEl,
			submitForm,
			isEditorMode,
		};
	},
	render () {
		const { _AllowedComponentsPlaceholderListEmptyLabel: emptyLabel } = this;
		const shouldRenderPlaceholderList = AuthoringUtils.isInEditor() && this.allowedComponents?.applicable && emptyLabel;

		if (shouldRenderPlaceholderList) {
			return (
				<div
					{...this.wrapperHtmlAttributes}
					class={this.mergedWrapperClass}
					key={this.componentId}
					data-component="MHLayoutContainer"
					data-use-theme={siteName}
				>
					<AllowedComponentsPlaceholderList
						title={this.title}
						emptyLabel={emptyLabel}
						components={this.allowedComponents.components}
						placeholderClass={this.placeholderProps.class}
						cqPath={this.cqPath}
					/>
				</div>
			);
		}

		if (this.props.isEnable && !this.isLoggedIn && !this.isEditorMode) {
			return (
				<div class="form-wrapper-container w-full flex justify-center flex-col items-center text-center m-auto >lg:w-1/2 p-4">
					<FormWrapper
						ref="formRef"
						class="form-wrapper w-full"
						focusFirstErrorElOnInvalidSubmit={true}
						onSubmitValid={this.submitForm}
					>
						<TextField
							v-model={this.userName}
							class="form-element mb-2"
							validation={yup.string().required()}
							rootAttrs={
								{ class: 'mb-5' }
							}
							ariaLabel="user name"
							name="username"
							autocomplete="off"
						>
						</TextField>

						<TextField
							v-model={this.password}
							class="form-element"
							validation={yup.string().required()}
							rootAttrs={
								{ class: 'mb-5' }
							}
							ariaLabel="password"
							name="password"
							autocomplete="off"
						>
						</TextField>

						{
							this.props.recaptchaType === 'google-recaptcha' ?
								<RecaptchaGoogle ref="recaptchaGoogleEl" siteKey={this.siteKey} /> :
								<RecaptchaMT ref="recaptchaMTEl" siteKey={this.siteKey} />
						}

						{
							this.isLoading ?
								<AppSpinner class="mx-auto my-auto" /> :
								<CTAButton type="submit">
									{this.props.loginBtnText}
								</CTAButton>
						}
						
						<div class="errorMessage-container text-semantic-red-base mb-3">
							<p
								v-html-sanitize={this.loginErrorMsg}
								class="error-msg-wrap leading-tight text-md w-full mt-1 empty:hidden"
							>
							</p>
						</div>
					</FormWrapper>
				</div>
			);
		}

		return (
			<div
				{...this.wrapperHtmlAttributes}
				{...this.$attrs}
				class={this.mergedWrapperClass}
				key={this.componentId}
				data-component="MHLayoutContainer"
				data-use-theme={siteName}
			>
				<div {...this.innerContainerWrapperAttrs}>
					{ (AuthoringUtils.isInEditor() && (this.props.isHideInDesktop || this.props.isHideInMobile)) ?
						<div class="py-2 px-4 bg-secondary-purple-light text-white">
							<h5>
								This Layout Container is set to be hidden in
								<span class="ml-1.5">
									{ (this.props.isHideInDesktop && this.props.isHideInMobile) ?
										'Desktop and Mobile'
										:
										(
											this.props.isHideInMobile ? 'Mobile' : 'Desktop'
										)
									}
								</span>
							</h5>
						</div>
					: null }
					{ this.getMainContent() }
				</div>
				<div class={Constants._PLACEHOLDER_CLASS_NAMES}></div>
			</div>
		);
	},
});


export default MHLayoutContainer;
