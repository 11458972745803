<script>
import { computed } from 'vue';
export default {
	name: 'CTAButton',
	editConfig: {
		emptyLabel: 'CTA Button',
		isEmpty (props) {
			return !props.ctaText;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import AppHyperlink from '~/components/AppHyperlink.vue';
import { pushAA_click } from '~/logic/adobe-analytic';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';

const { isEditorMode } = useGlobalAEMState();

const props = defineProps({
	href: { type: String, default: '' },
	ctaLinkType: { type: String, default: '' }, // <-- 'self' | 'blank' | 'download' 
	ctaText: { type: String, default: '' },
	styleType: { type: String, default: 'primary' }, // <-- 'primary' | 'secondary' | 'text-primary' | 'text-white' | 'arrow-cta-left' | 'arrow-cta-right'
	sizeType: { type: String, default: 'small' }, // <-- 'extra-small' | 'small' | 'medium' | 'large' | 'auto'
	aemProperties: { type: Object, default: null },
	isExternalLink: { type: Boolean, default: false },
	ctaSizing: { type: String, default: 'auto' }, // <-- 'fluid' | 'auto'
	alignmentDesktop: { type: String, default: 'center' }, // <-- 'center' | 'left' | 'right'
	alignmentMobile: { type: String, default: 'center' }, // <-- 'center' | 'left' | 'right'
	iconType: { type: String, default: 'icon-font-awesome-id' }, // <-- 'icon-image' | 'icon-font-awesome-id'
	iconIdLeft: { type: String, default: '' }, // <-- 'circle-plus' | 'heart' | ...
	iconIdRight: { type: String, default: '' }, // <-- 'circle-plus' | 'heart' | ...
	iconStyleLeft: { type: String, default: 'regular' }, // <-- 'solid' | 'regular' | 'thin' | 'light'
	iconStyleRight: { type: String, default: 'regular' }, // <-- 'solid' | 'regular' | 'thin' | 'light'
	iconImageLeft: { type: String, default: '' },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	isSubmit: { type: Boolean, default: false },
	containerStyle: { type: [String, Object, Array], default: '' },
	containerClass: { type: [String, Object, Array], default: '' },
	componentIdAA: { type: String, default: '' },
	supplementaryHiddenText: { type: String, default: '' },
	verticalSpacing: { type: Number, default: 0 },
});

const siteName = window.siteName;

const emit = defineEmits([
	// 'update:modelValue',
]);

const isPressed = ref(false);

const wrapperClass = computed(() => {
	return [
		`${ props.href ? 'inline-flex' : 'flex' }`,
		`rounded-5xl justify-center gap-2`,
		`styleType-${props.styleType}`,
		`sizeType-${computedStyleSize.value}`,
		props.ctaSizing === 'fluid' ? 'w-full' : 'w-auto',
		isPressed.value ? 'is-mouse-down' : '',
		{
			'mx-auto': props.alignmentDesktop === 'center',
			'mr-auto ml-0': props.alignmentDesktop === 'left',
			'ml-auto mr-0': props.alignmentDesktop === 'right',
		},
		{
			'md:mx-auto': props.alignmentMobile === 'center',
			'md:mr-auto md:ml-0': props.alignmentMobile === 'left',
			'md:ml-auto md:mr-0': props.alignmentMobile === 'right',
		},
	];
});

const computedStyleSize = computed(() => {
	if (props.styleType.includes('text') && props.sizeType === 'extra-small') return 'small';
	if (props.styleType.includes('text') && props.sizeType === 'small') return 'medium';
	if (props.styleType.includes('text') && props.sizeType === 'medium') return 'large';
	if (props.styleType.includes('arrow-cta') && props.sizeType === 'small') return 'medium';
	return props.sizeType;
});

const wrapperAttrs = computed(() => {
	const attrs = useAttrs();
	if (props.href) {
		// <AppHyperlink> tag
		return {
			href: computedHref.value,
			...(props.ctaLinkType === 'download' ? { download: '' } : null),
			...(props.ctaLinkType === 'blank' ? { target: '_blank' } : null),
			...attrs,
			class: [wrapperClass.value, attrs.class],
			showExternalLinkIcon: false,
		};
	} else {
		// <button> tag
		return {
			type: isSubmitButton.value ? 'submit' : 'button',
			...attrs,
			class: [wrapperClass.value, attrs.class],
		};
	}
});


const isPrimarySecondaryButton = computed(() => {
	return props.styleType === 'primary' || props.styleType === 'secondary';
});

const isIconImage = computed(() => {
	return props.iconType === 'icon-image' && props.iconImageLeft;
});

const isIconID = computed(() => {
	return props.iconType === 'icon-font-awesome-id' && props.iconIdLeft;
});

const isSubmitButton = computed(() => {
	return props.isSubmit && isPrimarySecondaryButton.value;
});

const isTel = computed(() => {
	let regex = /^\+?\d+(-\d+)*$/;
	return props.href && regex.test(props.href);
});

const isEmail = computed(() => {
	let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
	return props.href && regex.test(props.href);
});

const computedHref = computed(() => {
	if (isTel.value) {
		return `tel:${props.href}`;
	}
	if (isEmail.value) {
		return `mailto:${props.href}`;
	}
	return props.href;
});
const verticalSpacingClass = computed(() => {
	return `mt-${props.verticalSpacing}`;
});


const rootEl = ref(null);
function handleAATrigger (event) {
	if ('__aa_listener' in rootEl.value) {
		return true;
	}
	const isBanner = !!event.target.closest('.Banner');
	pushAA_click([
		{
			clickName: props.ctaText,
			clickComponentType: isBanner ? 'Banner' : ( props.href ? 'URL' : 'Button' ),
			componentName: isBanner ? 'Banner' : 'CTAButton',
			componentID: props.componentIdAA,
		},
		{
			name: props.ctaText,
			type: !props.href ? 'other' : isUrlMatchCurrentHostname(props.href) ? 'other' : 'exit',
		},
	]);
}

const computedCTATag = computed(() => {
	if (!props.href) return 'button';
	if (props.ctaLinkType === 'download') return 'a';
	return AppHyperlink;
});

</script>

<template>
<div v-if="isEditorMode && isHideInDesktop" class="generic-container py-10 text-center">
	<p>Hidden in: <span v-if="isHideInDesktop">Desktop</span> <span v-if="isHideInMobile">, Mobile</span></p>
</div>

<div
	v-else
	ref="rootEl"
	:data-use-theme="siteName"
	:class="[
		{
			'>md:hidden': isHideInDesktop,
			'hidden >md:block': isHideInMobile,
			'text-right': props.href && props.alignmentDesktop === 'right',
			'text-left': props.href && props.alignmentDesktop === 'left',
			'text-center': props.href && props.alignmentDesktop === 'center',
			'md:text-right': props.href && props.alignmentMobile === 'right',
			'md:text-left': props.href && props.alignmentMobile === 'left',
			'md:text-center': props.href && props.alignmentMobile === 'center',
		},
		props.containerClass,
		verticalSpacingClass,
	]"
	:style="props.containerStyle"
>
	<component
		:is="computedCTATag"
		class="CTAButton"
		:class="{
			'sizeType-text-primary': props.styleType.includes('text-primary'),
			'sizeType-text-white': props.styleType.includes('text-white'),
			'sizeType-arrow-cta-left': props.styleType.includes('arrow-cta-left'),
			'sizeType-arrow-cta-right': props.styleType.includes('arrow-cta-right'),
		}"
		v-bind="wrapperAttrs"
		data-aa-target
		@click="handleAATrigger"
		@mousedown="isPressed = true"
		@keydown.enter.space="isPressed = true"
		@mouseup="isPressed = false"
		@keyup.enter.space="isPressed = false"
		@mouseout="isPressed = false"
	>
		<span v-if="isIconImage" class="icon-image mt-0.5">
			<img
				:src="iconImageLeft"
				alt=""
			/>
		</span>

		<FontAwesomeLazy
			v-else-if="isIconID"
			class="icon fill-current mt-0.5"
			:iconId="iconIdLeft"
			:iconStyle="iconStyleLeft"
		/>

		<icon-far-chevron-left v-if="props.styleType.includes('arrow-cta-left') && !isExternalLink && ctaLinkType !== 'download'" class="icon icon-chevron-left inline-block" />

		<span v-if="props.ctaText" class="label basis-0 flex-grow">{{ props.ctaText }}</span>
		
		<slot></slot>

		<FontAwesomeLazy
			v-if="isExternalLink"
			class="icon fill-current mt-0.5"
			iconId="arrow-up-right-from-square"
			:iconStyle="iconStyleRight"
		/>

		<FontAwesomeLazy
			v-if="iconIdRight"
			class="icon fill-current mt-0.5"
			:iconId="iconIdRight"
			:iconStyle="iconStyleRight"
		/>

		<icon-far-arrow-right v-if="props.styleType.includes('text') && !isExternalLink && ctaLinkType !== 'download'" class="icon icon-arrow-right inline-block" />

		<icon-far-chevron-right v-if="props.styleType.includes('arrow-cta-right') && !isExternalLink && ctaLinkType !== 'download'" class="icon icon-chevron-right inline-block" />
		
		<span v-if="props.supplementaryHiddenText" class="sr-only">&nbsp;{{ props.supplementaryHiddenText }}</span>
	</component>
</div>

</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';
@use '~/styles/partials/_typography.scss';



// ------ Styles concerning sizeType ------
.CTAButton {
	text-decoration: none;

	@mixin sizeType-extra-small () {
		min-width: 80px;
		@apply py-2.5 px-4;
		@include typography.typo-body-text-3;
		line-height: 1.084;
		
		.icon {
			width: 12px;
			height: auto;
		}
	}
	
	@mixin sizeType-small () {
		min-width: 120px;
		@apply py-4 px-10;
		@include typography.typo-body-text-2;
		line-height: 1.1429;
		
		.icon {
			width: 12px;
			height: auto;
		}
	}

	@mixin sizeType-medium () {
		min-width: 120px;
		@apply py-4 px-10;
		@include typography.typo-body-text-1;
		line-height: 1.375;
		
		.icon {
			width: 14px;
			height: auto;
		}

		.icon-arrow-right,
		.icon-chevron-left,
		.icon-chevron-right {
			width: 16px;
		}
	}

	@mixin sizeType-large () {
		min-width: 120px;
		@apply py-5 px-10;
		@include typography.typo-body-text-1;
		line-height: 1.5;
		
		.icon {
			width: 16px;
			height: auto;
		}
	}
	
	&.sizeType-extra-small {
		@include sizeType-extra-small;
	}

	&.sizeType-small {
		@include sizeType-small;
	}

	&.sizeType-medium {
		@include sizeType-medium;
	}

	&.sizeType-large {
		@include sizeType-large;
	}

	&.sizeType-auto {
		@include sizeType-medium;
		
		@media #{var.$query-max-md} {
			@include sizeType-small;
		}
	}

	&:hover {
		.icon-arrow-right,
		.icon-chevron-right {
			transform: translateX(2px);
		}

		.icon-chevron-left {
			transform: translateX(-2px);
		}
	}
}

.styleType-primary,
.styleType-secondary {
	&.sizeType-medium {
		.icon {
			margin-top: 4px;
		}
		
		.icon-arrow-right {
			margin-top: 4px;
		}
	}

	&.sizeType-large {
		.icon {
			margin-top: 4px;
		}
	}
}


// ------ Styles concerning styleType Primary ------
.CTAButton {
	transition: all 0.25s;
	
	&.styleType-primary {
		border: 1.5px solid var(--primary-blue-base);
		background-color: var(--primary-blue-base);
		color: white;
		align-items: flex-start;
		
		&:hover {
			background-color: var(--primary-blue-light);
			border-color: var(--primary-blue-light);
		}

		&.is-mouse-down {
			background-color: var(--secondary-blue-extradark);
			border-color: var(--secondary-blue-extradark);
		}

		&[disabled], &:disabled, &.is-disabled {
			pointer-events: none;
			color: var(--neutral-grey-dark);
			background-color: var(--neutral-grey-extralight);
			border-color: var(--neutral-grey-extralight);
		}
	}
}

// ------ Styles concerning styleType Secondary ------
.CTAButton {
	&.styleType-secondary {
		background-color: white;
		border: 1.5px solid var(--primary-blue-base);
		color: var(--primary-blue-base);
		align-items: flex-start;
		
		&:hover {
			background-color: var(--primary-blue-light);
			border-color: var(--primary-blue-light);
			color: white;
		}

		&.is-mouse-down {
			background-color: var(--secondary-blue-extradark);
			border-color: var(--secondary-blue-extradark);
			color: white;
		}

		&[disabled], &:disabled, &.is-disabled {
			pointer-events: none;
			cursor: default;
			color: var(--neutral-grey-dark);
			background-color: white;
			border-color: var(--neutral-grey-light);
		}
	}
}

.sizeType-text-primary,
.sizeType-text-white,
.sizeType-arrow-cta-left,
.sizeType-arrow-cta-right {
	transition: transform .3s ease-in-out;
	padding: 0 !important;
	min-width: auto !important;
	display: inline-block;

	.icon,
	.icon-image {
		display: inline-block;
		vertical-align: middle;
		@apply mt-0 mb-0.5 mr-2 rtl:mr-0 rtl:ml-2;
	}

	.icon:last-child {
		@apply mt-0 mb-0.5 ml-2 rtl:ml-0 rtl:mr-2;
	}
}

.sizeType-text-primary,
.sizeType-arrow-cta-left,
.sizeType-arrow-cta-right {
	&:hover {
		color: var(--primary-blue-light);

		.icon-arrow-right {
			fill: var(--primary-blue-light);
		}
	}

	&.is-mouse-down {
		color: var(--secondary-blue-extradark);

		.icon-arrow-right {
			fill: var(--secondary-blue-extradark);
		}
	}
}

.sizeType-text-white {
	color: var(--neutral-white-base);
	.icon-arrow-right {
		fill: var(--neutral-white-base);
	}
	&:hover {
		color: var(--neutral-grey-extralight);

		.icon-arrow-right {
			fill: var(--neutral-grey-extralight);
		}
	}

	&.is-mouse-down {
		color: var(--neutral-grey-light);

		.icon-arrow-right {
			fill: var(--neutral-grey-light);
		}
	}
}

.icon-image {
	width: 25px;
	margin-top: -5px;
}

.icon-arrow-right,
.icon-chevron-left,
.icon-chevron-right {
	transition: transform .3s ease-in-out;
	fill: var(--primary-blue-base);
}


[data-use-theme="MAG"] {
	.CTAButton {
		&.styleType-primary {
			border-color: var(--primary-midnightgreen-base);
			background-color: var(--primary-midnightgreen-base);
			
			&:hover {
				background-color: var(--primary-midnightgreen-light);
				border-color: var(--primary-midnightgreen-light);
			}

			&.is-mouse-down {
				background-color: var(--secondary-midnightgreen-extradark);
				border-color: var(--secondary-midnightgreen-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
                background-color: var(--neutral-grey-extralight);
                border-color: var(--neutral-grey-extralight);
            }
		}
	}
	.CTAButton {
		&.styleType-secondary {
			border-color: var(--primary-midnightgreen-base);
			color: var(--primary-midnightgreen-base);
			
			&:hover {
				background-color: var(--primary-midnightgreen-light);
				border-color: var(--primary-midnightgreen-light);
				color: var(--neutral-white-base);
			}

			&.is-mouse-down {
				background-color: var(--secondary-midnightgreen-extradark);
				border-color: var(--secondary-midnightgreen-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
                color: var(--neutral-grey-dark);
                border-color: var(--neutral-grey-light);
            }
		}
	}
	.sizeType-text-primary {
		color: var(--primary-midnightgreen-base);

		.icon-arrow-right {
			fill: var(--primary-midnightgreen-base);
		}

		&:hover {
			color: var(--primary-midnightgreen-light);

			.icon-arrow-right {
				fill: var(--primary-midnightgreen-light);
			}
		}

		&.is-mouse-down {
			color: var(--secondary-midnightgreen-extradark);

			.icon-arrow-right {
				fill: var(--secondary-midnightgreen-extradark);
			}
		}
	}
}

[data-use-theme="MHH"] {
	.CTAButton {
		&.styleType-primary {
			border-color: var(--primary-mhh-teal-base);
			background-color: var(--primary-mhh-teal-base);
			
			&:hover {
				background-color: var(--secondary-mhh-teal-dark);
				border-color: var(--secondary-mhh-teal-dark);
			}

			&.is-mouse-down {
				background-color: var(--secondary-mhh-teal-extradark);
				border-color: var(--secondary-mhh-teal-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
				background-color: var(--neutral-grey-extralight);
				border-color: var(--neutral-grey-extralight);
			}
		}
	}
	.CTAButton {
		&.styleType-secondary {
			border-color: var(--primary-mhh-teal-base);
			color: var(--primary-mhh-teal-base);
			
			&:hover {
				background-color: var(--secondary-mhh-teal-dark);
				border-color: var(--secondary-mhh-teal-dark);
				color: var(--neutral-white-base);
			}

			&.is-mouse-down {
				background-color: var(--secondary-mhh-teal-extradark);
				border-color: var(--secondary-mhh-teal-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
				color: var(--neutral-grey-dark);
				border-color: var(--neutral-grey-light);
			}
		}
	}

	.sizeType-text-primary {
		color: var(--secondary-mhh-teal-dark);
			
		.icon-arrow-right {
			fill: var(--secondary-mhh-teal-dark);
		}

		&:hover {
			color: var(--secondary-mhh-teal-dark);

			.icon-arrow-right {
				fill: var(--secondary-mhh-teal-dark);
			}
		}

		&.is-mouse-down {
			color: var(--secondary-mhh-teal-extradark);

			.icon-arrow-right {
				fill: var(--secondary-mhh-teal-extradark);
			}
		}
	}
}

[data-use-theme="firefly"] {
	.CTAButton {
		&.styleType-primary {
			background-color: var(--primary-firefly-orange-base);
			color: var(--secondary-firefly-black-base);
			border-color: var(--primary-firefly-orange-base);
			font-weight: 600;
			
			&:hover {
				background-color: var(--secondary-firefly-orange-medium);
				border-color: var(--secondary-firefly-orange-medium);
			}
			
			&:focus {
				background-color: var(--secondary-firefly-orange-medium);
				border-color: var(--semantic-firefly-blue-base);
			}

			&.is-mouse-down {
				background-color: var(--secondary-firefly-orange-extradark);
				color: var(--neutral-firefly-white-base);
				border-color: var(--secondary-firefly-orange-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
				background-color: var(--neutral-firefly-grey-extralight);
				color: var(--neutral-firefly-grey-medium);
				border-color: var(--neutral-firefly-grey-extralight);
			}
		}
	}
	.CTAButton {
		&.styleType-secondary {
			border-color: var(--primary-firefly-orange-base);
			color: var(--secondary-firefly-orange-extradark);
			
			&:hover {
				background-color: var(--neutral-firefly-white-base);
				color: var(--primary-firefly-orange-base);
				border-color: var(--primary-firefly-orange-base);
			}

			&:focus {
				background-color: var(--neutral-firefly-white-base);
				border-color: var(--semantic-firefly-blue-base);
			}

			&.is-mouse-down {
				background-color: var(--secondary-firefly-orange-extradark);
				color: var(--neutral-firefly-white-base);
				border-color: var(--secondary-firefly-orange-extradark);
			}

			&[disabled], &:disabled, &.is-disabled {
				background-color: var(--neutral-firefly-grey-extralight);
				color: var(--neutral-firefly-grey-medium);
				border-color: var(--neutral-firefly-grey-medium);
			}
		}
	}

	.sizeType-text-primary {
		color: var(--primary-firefly-orange-base);
			
		.icon-arrow-right {
			fill: var(--primary-firefly-orange-base);
		}

		&:hover {
			color: var(--secondary-firefly-orange-medium);

			.label {
				text-decoration: underline;
				text-decoration-color: var(--secondary-firefly-orange-medium);
			}

			.icon-arrow-right {
				fill: var(--secondary-firefly-orange-medium);
			}
		}

		&:focus {
			outline: 1.5px solid var(--semantic-firefly-blue-base);
			border-radius: 8px;
		}

		&.is-mouse-down {
			color: var(--secondary-firefly-orange-extradark);

			.icon-arrow-right {
				fill: var(--secondary-firefly-orange-extradark);
			}
		}

		&[disabled], &:disabled, &.is-disabled {
			color: var(--neutral-firefly-grey-medium);
			border-color: var(--neutral-firefly-grey-medium);

			.icon-arrow-right {
				fill: var(--neutral-firefly-grey-medium);
			}
		}
	}
}
</style>
