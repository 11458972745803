<script>
export default {
	name: 'LoginMenu',
	inheritAttrs: false,
};
</script>

<script setup>
import OverlayDrawer from '~/components/OverlayDrawer.vue';
import MobileDrawerHeader from '~/components/AppHeader/MobileDrawerHeader.vue';
import useDisableScroll from '~composables/useDisableScroll.js';
import { onClickOutside, useEventBus } from '@vueuse/core';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import AppHyperlink from '~/components/AppHyperlink.vue';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry';

const { isRTL } = useIsRTL();

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	preLoginItems: { type: Array, default: () => [] },
	alternateRedirectLoginUrl: { type: String, default: '' },
	preLoginMenuLabel: { type: String, default: 'Log in/Sign up with' },
	isHideMobileSearch: { type: Boolean, default: false },
	myAccountLabel: { type: String, default: '' },
	myAccount: { type: Array, default: () => [] },
	mhExplorerLabel: { type: String, default: '' },
	mhExplorerPreloginUrl: { type: String, default: '' },
	mhExplorerPostloginUrl: { type: String, default: '' },
	mhExplorerExternalIcon: { type: Boolean, default: false },
	mhExplorerOpenInNewTab: { type: Boolean, default: false },
	mhbizProLabel: { type: String, default: '' },
	mhbizProUrl: { type: String, default: '' },
	mhbizProExternalIcon: { type: Boolean, default: false },
	mhbizProOpenInNewTab: { type: Boolean, default: false },
	enrichBizConnectLabel: { type: String, default: '' },
	enrichBizConnectUrl: { type: String, default: '' },
	enrichBizConnectExternalIcon: { type: Boolean, default: false },
	enrichBizConnectOpenInNewTab: { type: Boolean, default: false },
	logOutLabel: { type: String, default: 'Logout' },
	exploreMoreLabel: { type: String, default: 'EXPLORE MORE' },
	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'update:isVisible',
]);

const { deleteLocalStorage } = usePersistencyWithExpiry('userInfo', {});

const internalVisible = ref(props.isVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});
watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

useDisableScroll(internalVisible);

const hideOverlay = () => {
	internalVisible.value = false;
};


const handleRootBlurredWithin = (event) => {
	internalVisible.value = false;
};


const rootEl = ref(null);

onClickOutside(rootEl, (event) => {
	internalVisible.value = false;
});


const {
	isLoggedIn,
	userInfo,
	logout,
} = useAuthStore();

const globalBookFlightBus = useEventBus('booking-widget:enrich-points-toggle-switch');

const handleLogout = (event) => {
	logout();
	deleteLocalStorage('userInfo'); // for MHE
	globalBookFlightBus.emit('update-toggle-switch', 'logout');
	emit('update:isVisible', false);
};

const siteName = window.siteName;

</script>

<template>
<template v-if="isMobileViewport">
	<!-- Mobile -->
	<OverlayDrawer
		:isVisible="props.isVisible"
		class="text-white"
		style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
		:data-use-theme="siteName"
		@update:is-visible="$emit('update:isVisible', $event)"
	>
		<div class="overflow-y-auto max-h-screen">
			<MobileDrawerHeader
				:drawerIsHideMobileSearch="props.isHideMobileSearch"
				class="sticky top-0 bg-secondary-blue-extradark"
				@click:header-x="internalVisible = false"
			/>
			<div
				v-if="!isLoggedIn"
				class="px-6 pt-4 pb-6"
			>
				<div class="typo-category-title">{{ props.preLoginMenuLabel }}</div>
				<div
					v-for="(preLoginMobileItem, index) in props.preLoginItems"
					:key="`prelogin-mobile-${index}`"
				>
					<div class="flex mt-4 gap-y-4 flex-col">
						<AppHyperlink
							v-aa="[
								{
									clickName: preLoginMobileItem.loginOptionTitle,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: preLoginMobileItem.loginOptionTitle,
									type: preLoginMobileItem.loginOptionExternalLink ? 'exit' : 'other',
								},
							]"
							class="block border-2 border-neutral-grey-light rounded-xl p-4 bg-primary-blue-dark text-neutral-white-base"
							style="--a-tag-color-hover: var(--neutral-white-base);"
							:href="(index === 0 && props.alternateRedirectLoginUrl) ? props.alternateRedirectLoginUrl : preLoginMobileItem.loginOptionUrl"
							:target="preLoginMobileItem.openInNewTabLogin ? '_blank' : '_self'"
							:showExternalLinkIcon="false"
						>
							<h6 class="menu-item-title flex gap-3 pb-2">
								{{ preLoginMobileItem.loginOptionTitle }}
								<icon-far-arrow-up-right-from-square
									v-if="preLoginMobileItem.loginOptionExternalLink"
									class="fill-current"
								/>
							</h6>
							<p class="menu-item-description typo-body-text-2 basis-full">{{ preLoginMobileItem.loginOptionDescription }}</p>
						</AppHyperlink>
					</div>
				</div>
			</div>
			<div
				v-else
				class="px-6 pt-4 pb-6"
			>
				<div class="login-grid items-center mb-8">
					<div class="login-avatar bg-neutral-grey-ultradark text-neutral-grey-base">
						{{ userInfo.lastName?.charAt(0) }}{{ userInfo.firstName?.charAt(0) }}
					</div>
					<div class="login-details mb-2 font-bold">{{ userInfo.lastName }} {{ userInfo.firstName }}</div>
					<div class="login-details mb-2 text-sm">{{ userInfo.userTierLabel }}</div>
					<div class="login-details"><span class="font-bold">{{ userInfo.freqMilesPoint?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span> <span class="text-sm">Enrich Points</span></div>
				</div>
				<div class="login-my-account mb-6">
					<div class="mb-5 text-xs font-bold text-neutral-grey-extradark">{{ props.myAccountLabel }}</div>
					<div
						v-for="(item, index) in props.myAccount"
						:key="index"
						class="mb-4 flex items-center"
					>
						<AppHyperlink
							v-aa="[
								{
									clickName: item.ctaText,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: item.ctaText,
									type: 'other',
								},
							]"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							class="text-neutral-white-base flex items-center"
							:href="item.ctaUrl"
						>
							<FontAwesomeLazy
								:iconId="item.iconFontAwesomeId"
								iconStyle="regular"
								class="account-icon w-6 h-6 mr-2 rtl:(ml-2 mr-0) fill-current"
							>
							</FontAwesomeLazy>
							<span>{{ item.ctaText }}</span>
						</AppHyperlink>
					</div>
					<CTAButton
						v-aa="[
							{
								clickName: props.logOutLabel,
								clickComponentType: 'Button',
								componentName: 'LoginMenu',
								componentID: props.componentIdAA,
							},
							{
								name: props.logOutLabel,
								type: 'other',
							},
						]"
						:ctaText="props.logOutLabel"
						styleType="secondary"
						sizeType="small"
						iconType="icon-font-awesome-id"
						iconIdLeft="arrow-right-from-bracket"
						iconStyleLeft="regular"
						:alignmentMobile="isRTL ? 'right' : 'left'"
						@click="handleLogout"
					>
					</CTAButton>
				</div>
				
				<template v-if="props.mhExplorerPostloginUrl || props.enrichBizConnectUrl || props.mhbizProUrl">
					<hr class="bg-neutral-grey-ultradark" />
					<div class="mt-6 flex flex-col">
						<div class="mb-6 text-xs font-bold text-neutral-grey-extradark">{{ props.exploreMoreLabel }}</div>
						<AppHyperlink
							v-if="props.mhExplorerPostloginUrl"
							v-aa="[
								{
									clickName: props.mhExplorerLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.mhExplorerLabel,
									type: props.mhExplorerExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color: var(--neutral-white-base);"
							:href="props.mhExplorerPostloginUrl"
							:target="props.mhExplorerOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.mhExplorerExternalIcon"
						>
							<span>{{ props.mhExplorerLabel }}</span>
						</AppHyperlink>
						<AppHyperlink
							v-if="props.enrichBizConnectUrl"
							v-aa="[
								{
									clickName: props.enrichBizConnectLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.enrichBizConnectLabel,
									type: props.enrichBizConnectExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color: var(--neutral-white-base);"
							:href="props.enrichBizConnectUrl"
							:target="props.enrichBizConnectOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.enrichBizConnectExternalIcon"
						>
							<span>{{ props.enrichBizConnectLabel }}</span>
						</AppHyperlink>
						<AppHyperlink
							v-if="props.mhbizProUrl"
							v-aa="[
								{
									clickName: props.mhbizProLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.mhbizProLabel,
									type: props.mhbizProExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color: var(--neutral-white-base);"
							:href="props.mhbizProUrl"
							:target="props.mhbizProOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.mhbizProExternalIcon"
						>
							<span>{{ props.mhbizProLabel }}</span>
						</AppHyperlink>
					</div>
				</template>
			</div>
		</div>
	</OverlayDrawer>
</template>

<template v-else>
	<!-- Desktop -->
	<Teleport to="body">
		<div class="generic-backdrop"></div>
	</Teleport>
	<!-- 'transform' class is for Safari fix -->
	<div
		v-if="isVisible"
		ref="rootEl"
		v-focus-within
		class="LoginMenu bg-white overflow-hidden transform"
		v-bind="$attrs"
		tabindex="-1"
		:data-use-theme="siteName"
		@keydown.esc.capture="hideOverlay"
		@blur-within="handleRootBlurredWithin"
	>
		<div class="login-dropdown-container p-8">
			<div v-if="!isLoggedIn">
				<div class="typo-category-title">{{ props.preLoginMenuLabel }}</div>
				<div
					v-for="(preLoginDesktopItem, index) in props.preLoginItems"
					:key="`prelogin-desktop-${index}`"
				>
					<div class="flex mt-4 gap-y-4 flex-col">
						<AppHyperlink
							v-aa="[
								{
									clickName: preLoginDesktopItem.loginOptionTitle,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: preLoginDesktopItem.loginOptionTitle,
									type: 'other',
								},
							]"
							class="block border-2 border-neutral-grey-light rounded-xl p-4 bg-neutral-grey-ultralight"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							:href="(index === 0 && props.alternateRedirectLoginUrl) ? props.alternateRedirectLoginUrl : preLoginDesktopItem.loginOptionUrl"
							:target="preLoginDesktopItem.openInNewTabLogin ? '_blank' : '_self'"
							:showExternalLinkIcon="false"
						>
							<h6 class="menu-item-title flex gap-3 pb-2">
								{{ preLoginDesktopItem.loginOptionTitle }}
								<icon-far-arrow-up-right-from-square
									v-if="preLoginDesktopItem.loginOptionExternalLink"
									class="fill-current"
								/>
							</h6>
							<p class="menu-item-description typo-body-text-2 basis-full">{{ preLoginDesktopItem.loginOptionDescription }}</p>
						</AppHyperlink>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="login-grid items-center mb-8">
					<div class="login-avatar bg-secondary-blue-ultralight text-primary-blue-dark">
						{{ userInfo.lastName?.charAt(0) }}{{ userInfo.firstName?.charAt(0) }}
					</div>
					<div class="login-details mb-2 font-bold">{{ userInfo.lastName }} {{ userInfo.firstName }}</div>
					<div class="login-details mb-2 text-sm">{{ userInfo.userTierLabel }}</div>
					<div class="login-details"><span class="font-bold">{{ userInfo.freqMilesPoint?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span> <span class="text-sm">Enrich Points</span></div>
				</div>
				<div class="login-my-account mb-6">
					<div class="mb-5 text-xs font-bold text-neutral-grey-extradark">{{ props.myAccountLabel }}</div>
					<div
						v-for="(item, index) in props.myAccount"
						:key="index"
						class="mb-4 flex items-center"
					>
						<AppHyperlink
							v-aa="[
								{
									clickName: item.ctaText,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: item.ctaText,
									type: 'other',
								},
							]"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							:href="item.ctaUrl"
						>
							<FontAwesomeLazy
								:iconId="item.iconFontAwesomeId"
								iconStyle="regular"
								class="account-icon w-6 h-6 mr-2 rtl:(ml-2 mr-0) fill-current"
							>
							</FontAwesomeLazy>
							<span>{{ item.ctaText }}</span>
						</AppHyperlink>
					</div>
					<CTAButton
						v-aa="[
							{
								clickName: props.logOutLabel,
								clickComponentType: 'Button',
								componentName: 'LoginMenu',
								componentID: props.componentIdAA,
							},
							{
								name: props.logOutLabel,
								type: 'other',
							},
						]"
						:ctaText="props.logOutLabel"
						styleType="secondary"
						sizeType="small"
						iconType="icon-font-awesome-id"
						iconIdLeft="arrow-right-from-bracket"
						iconStyleLeft="regular"
						:alignmentDesktop="isRTL ? 'right' : 'left'"
						@click="handleLogout"
					>
					</CTAButton>
				</div>
				
				<template v-if="props.mhExplorerPostloginUrl || props.enrichBizConnectUrl || props.mhbizProUrl">
					<hr />
					<div class="mt-6 flex flex-col">
						<div class="mb-6 text-xs font-bold text-neutral-grey-extradark">{{ props.exploreMoreLabel }}</div>
						<AppHyperlink
							v-if="props.mhExplorerPostloginUrl"
							v-aa="[
								{
									clickName: props.mhExplorerLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.mhExplorerLabel,
									type: props.mhExplorerExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							:href="props.mhExplorerPostloginUrl"
							:target="props.mhExplorerOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.mhExplorerExternalIcon"
						>
							<span>{{ props.mhExplorerLabel }}</span>
						</AppHyperlink>
						<AppHyperlink
							v-if="props.enrichBizConnectUrl"
							v-aa="[
								{
									clickName: props.enrichBizConnectLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.enrichBizConnectLabel,
									type: props.enrichBizConnectExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							:href="props.enrichBizConnectUrl"
							:target="props.enrichBizConnectOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.enrichBizConnectExternalIcon"
						>
							<span>{{ props.enrichBizConnectLabel }}</span>
						</AppHyperlink>
						<AppHyperlink
							v-if="props.mhbizProUrl"
							v-aa="[
								{
									clickName: props.mhbizProLabel,
									clickComponentType: 'URL',
									componentName: 'LoginMenu',
									componentID: props.componentIdAA,
								},
								{
									name: props.mhbizProLabel,
									type: props.mhbizProExternalIcon ? 'exit' : 'other',
								},
							]"
							class="mb-5"
							style="--a-tag-color-hover: var(--primary-blue-light);"
							:href="props.mhbizProUrl"
							:target="props.mhbizProOpenInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="props.mhbizProExternalIcon"
						>
							<span>{{ props.mhbizProLabel }}</span>
						</AppHyperlink>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>


</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.LoginMenu {
	position: fixed;
	z-index: 7000;
	// 100% minus container width, remaining balance divide 2, coz left and right side.
	// Then, add 1rem (minus is left, but add because we are using 'right' property) to account for padding
	right: calc( ((100% - var(--containerWidth)) / 2) + 1rem );
	width: 400px;
	// after applying transform to header, this 'top' need to change
	/* top: calc( var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight) + var(--headerHeight) ); */
	top: var(--headerHeight);
	transition: opacity 0.3s ease;
	min-height: 500px;
	@apply shadow-type-a;
	
	html[dir="rtl"] & {
		left: calc( ((100% - var(--containerWidth)) / 2) + 1rem );
		right: revert;
	}
}

.login-avatar {
	@apply items-center justify-center self-start row-span-3 rounded-full w-14 h-14 font-bold text-xl;

	display: flex;
	margin-top: 5px;
}

.login-dropdown-container {
	overflow-y: auto;
	height: auto;
	max-height: calc(100vh - var(--headerHeight));
}

.login-grid {
	@apply gap-x-4;

	display: grid;
	grid-template-columns: 56px 1fr;
}

.login-details {
	grid-column-start: 2;
}

.account-icon {

	:deep(svg) {
		margin: auto;
		margin-top: 0.2em;
		height: 1.2em;
		width: 1.2em;
	}
}

.LoginMenu[data-use-theme="firefly"] {
	:deep(.AppHyperlink) {
		border-bottom-right-radius: 0;
		
		&:hover {
			text-decoration: none;
			
			.menu-item-title {
				text-decoration: underline;
			}
		}
	}
	.menu-item-title {
		color: var(--primary-firefly-orange-base);
	}
	.menu-item-description {
		color: var(--text-color);
	}
}
</style>
